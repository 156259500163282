
import Base from '@/mixins/Base.vue';
import moment from 'moment';
import { defineComponent } from 'vue';

export default defineComponent({
    mixins: [Base],
    data(): any {
        return {
            moment,
            item: undefined as any,
            jobs: [
                {
                    starts_at: Date.now(),
                    full_address: 'Bitsingerweg 16, 3770 Val-meer',
                    products: [
                        {
                            name: 'EPC',
                            code: 'EPC.01',
                            quantity: 2,
                        },
                    ],
                    status: 'planned',
                },
                {
                    starts_at: Date.now(),
                    full_address: 'Bitsingerweg 16, 3770 Val-meer',
                    products: [
                        {
                            name: 'EPC',
                            code: 'EPC.01',
                            quantity: 2,
                        },
                    ],
                    status: 'planned',
                },
            ],
        };
    },
    mounted(): void {
        this.getItem(this.$route.params.id);
    },
    methods: {
        getItem(id: string): void {
            this.get(`projects/${this.$route.params.project_id}/steps/${id}`)
                .then(
                    ({ data }: any) => {
                        this.item = data.data;
                        this.item.starts_at = moment(this.item.starts_at)
                            .toDate();
                        this.item.ends_at = moment(this.item.ends_at)
                            .toDate();
                        (this.$refs.form as any).setValues(this.item);
                    },
                );
        },
        saveItem(): void {
            this.put(`projects/${this.$route.params.project_id}/steps/${this.$route.params.id}`, {
                ...this.item,
            })
                .then(() => {
                    this.$router.push({ name: 'projects.steps.list', ...this.$route.params });
                    this.$emit('reload');
                });
        },
    },
});
